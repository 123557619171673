<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon color="primary lighten-2" v-bind="attrs" v-on="on">
        <v-icon>mdi-information-outline</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline primary lighten-2 white--text">
        Détail de la règle
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="mt-3">
        <v-row dense>
          <v-col cols="12">
            <v-chip color="primary lighten-2" label text-color="white">
              {{ rule.scope }}
            </v-chip>
          </v-col>
          <v-col cols="12"
            ><span class="font-weight-medium">{{ rule.name }}</span></v-col
          >
        </v-row>

        <v-divider class="mt-2 mb-4"  />

        <v-row>
          <v-col cols="12" v-if="rule.description">
            <p class="font-weight-bold accent--text">Définition :</p>
            {{ rule.description }}
          </v-col>
          <v-col cols="12" v-if="rule.value">
            <p class="font-weight-bold accent--text">Valeur :</p>
            {{ rule.value }}
          </v-col>
          <v-col cols="12" v-if="rule.financial_risk">
            <p class="font-weight-bold accent--text">Risque Financier :</p>
            {{ rule.financial_risk }}
          </v-col>
          <v-col cols="12" v-if="rule.meta">
            <p class="font-weight-bold accent--text">Causes probables et exemples purement illustratifs et non exhaustifs :</p>
            <div v-for="(usecase, i) in rule.meta" :key="i">
              - {{ usecase }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    ruleId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState({
      rules: (state) => state.rules.rulesNames,
    }),

    rule: function () {
      const rule = this.rules[this.ruleId];

      const cleanedMetas = Object.keys(rule.meta).filter((m) =>
        m.startsWith("use_case")
      );

      return {
        ...rule,
        meta: cleanedMetas.reduce((acc, key) => {
          acc[key] = rule.meta[key];
          return acc;
        }, {}),
      };
    },
  },
};
</script>
